<template>
    <div>
    <el-tabs v-if="transactionAccount.sessionData.pgType!='STRIPE'" type="border-card" v-model="method" v-loading.fullscreen.lock="loading" :element-loading-text="loadingText" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" >
        <el-tab-pane name="CC">
            <span slot="label"><i class="el-icon-date"></i> Credit Card</span>
            <CreditCard @change="proceedForConses" @cardChanged="cardType" direction="row" :yearDigits="4" :noCard="true" :customer="customerAddress" :amount="amount" :currency="currency"></CreditCard>
        </el-tab-pane>
        <el-tab-pane name="ACH" :disabled="getAchStatus">
            <span slot="label"><i class="el-icon-date"></i> ACH</span>
            <ACH @change="proceedForConses" @cardChanged="cardType" :customer="customerAddress" :amount="amount" :currency="currency" ></ACH>
        </el-tab-pane>
    </el-tabs>
    <Stripe_CC v-else
        :pk="transactionAccount.sessionData.publicKey"
        :currency="currency" 
        :customer="customerAddress" 
        :description="txnAcId.description"
        :microservicePath="paymentApiUrl" 
        :pgAccountId="pgAcId"
        :payAmount="amount" 
        :txnAcId="txnAcId.sessionData._id"
    > </Stripe_CC>
    <!--  :pk="pk"  :redirectionPath="redirectionPath"  -->
</div>
</template>

<script>
import CreditCard from "./credit";
import ACH from "./ach";
import PaymentMethods from "@/mixins/payments";
import Stripe_CC from "./stripe_checkout";
export default {
    name: "paymentForCARD_ACH",
    components: {
        CreditCard,ACH,Stripe_CC
    },
    props:{
        paymentApiUrl: {
            type: String,
        },
        pgAcId:{
            type:String,
        },
        txnAcId:{
            type:Object,
        },
        pgType:{
            type:String
        },
        currency:{
            type:String
        },
        amount:{
            type:Number
        },
        transactionAccount:{
            type:Object,
        },
        data:{
            type:Object,
        },
        customerAddress:{
            type:Object
        },
        entityInfo:{
            type:Array
        }
    },
    data() {
        return {
            loading:false,
            loadingText:'Loading',
            /* Payment status  Fetching  variables deceleration */
            method:'CC',
            customer: {
                customerId: '',
                env: 0,
                // orgId: '',
                // locId: '',
                first_name: '',
                ba_email: '',
            },
            ccPayMethod: {
                payMethodId: '',
                env: 0,
                // orgId: '',
                // locId: '',
                cstoken: '', //this.customerToken,
                notes: '',
                name_on_card: '',
                card_type: '',
                account_number: '',
                expire_month: '',
                expire_year: '',
                card_verification_value: '',
                billing_address: {
                    city: '',
                    countryCode: 'US',
                    state: "NY",
                    street: '',
                    postalCode: ''
                }
            },
            achPayMethod:{
                    env : "0", 
                    orgId : "",
                    locId : "",
                    partnerAccountId: "",
                    cstoken : "",
                    account_holder: "",
                    account_number:"",
                    routing_number:"",
                    account_type:"CHECKING",
                    billing_address: {
                                    city: '',
                                    countryCode: 'US',
                                    state: "NY",
                                    street: '',
                                    postalCode: ''
                                }
            },
            checkoutInfo: {
                env: 0,
                // orgId: '',
                // locId: '',
                action: '',  //CARD ,ACH_LEGACY , for forte: sale
                authorization_amount: 0,
                currencyCode:'',
                paymethod_token: '',
                paymentType: '', //echeck
                sec_code: ""    //WEB for forte
            },
            customerInfo:{},
            payMethodInfo:{},
            paymentInfo:{},
            transactionInfo:{},
            setupSessionInfo:{},
            recurringConfirmation:0,
        
        }
    },
    mixins: [PaymentMethods],

    methods: {

        async proceedForConses(values){
            this.loading=true;
            this.loadingText= 'Fetching Payment Options.... Please wait .....';
            if(this.data && this.data.validations &&this.data.validations.transactionType !='NONE' && this.currency !='INR')
                {
                    // Get confirmation for save pay method
                    const h = this.$createElement;
                this.$msgbox({
                  title: 'Need Confirmation',
                  message: h('p', null, [
                    h('span', null, 'The client has configured this payment for automatic captures. Should we save the card/account tokens to facilitate the automatic capture of payments?'),
                  ]),
                  showCancelButton: true,
                  confirmButtonText: 'Yes',
                  cancelButtonText: 'No',
                  showClose:false,
                  closeOnClickModal:false,
                  closeOnPressEscape:false,
                  closeOnHashChange:false,
                //   cancelButtonText: 'Cancel',
                  beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                      instance.confirmButtonLoading = true;
                      instance.confirmButtonText = 'Loading...';
                      setTimeout(() => {
                        done();
                        setTimeout(() => {
                          instance.confirmButtonLoading = false;
                        }, 300);
                      }, 3000);
                    } else {
                      done();
                    }
                  }
                }).then(action => {
                    console.log("Confirm ",action);
                    this.recurringConfirmation=1;
                    this.redirectBlock(values)
                        
                    })
                    .catch(() => {
                        this.recurringConfirmation=0;
                        this.redirectBlock(values)         
                    });
                }
                else{
                   await this.redirectBlock(values)
                }

        },

        async redirectBlock(values)
        {
            if(values.type)
                    {
                         this.creditInfoChangedForAch(values);
                         
                    }
                    else{
                        this.creditInfoChanged(values);
                    }
        },


        async creditInfoChangedForAch(values)
        {
            //ACH Block

            this.customer.env= this.transactionAccount.isLive?1:0;
            this.achPayMethod.env = this.transactionAccount.isLive?1:0;

            /* Customer creation - Start */ 
            this.customer.first_name= values.name;
            this.customer.ba_email= values.email;

            await this.createCustomer(this.customer);
            if(!this.customerInfo.status)
            {
                this.$message.error(this.customerInfo.message);
                this.loading=false;
                this.loadingText='Loading ....';
                return 
            }
            /* Customer creation - End */

            /** Card method creation values  Start*/
            this.achPayMethod.cstoken=this.customerInfo.result.customer_token;
            this.achPayMethod.account_holder=values.name;
            this.achPayMethod.routing_number = values.routingNumber;
            this.achPayMethod.account_number= values.accountNumber;
            this.achPayMethod.account_type= values.type;
            this.achPayMethod.billing_address= {
                    city: values.city,
                    countryCode: values.country,
                    state: values.state,
                    street: values.address,
                    postalCode: values.zipCode
                };
            
           await  this.createAchMethod(this.achPayMethod);
            if(!this.payMethodInfo.status)
            {
                this.$message.error(this.payMethodInfo.message);
                this.loading=false;
                this.loadingText='Loading ....';
                return ;

            }

            await this.initiate_verify_Transaction();

        },
        async creditInfoChanged(values) {
            this.customer.env= this.transactionAccount.isLive?1:0;
            this.ccPayMethod.env = this.transactionAccount.isLive?1:0;

            /* Customer creation - Start */ 
            this.customer.first_name= values.name;
            this.customer.ba_email= values.email;

            await this.createCustomer(this.customer);
            if(!this.customerInfo.status)
            {
                this.$message.error(this.customerInfo.message);
                this.loading=false;
                this.loadingText='Loading ....';
                return 
            }
            /* Customer creation - End */


            /** Card method creation values  Start*/
            this.ccPayMethod.cstoken=this.customerInfo.result.customer_token;
            this.ccPayMethod.name_on_card=values.name;
            this.ccPayMethod.card_type = values.cardType;
            this.ccPayMethod.account_number= values.cardNumber.replace(/\s+/g, '');
            this.ccPayMethod.expire_month= values.expMonth;
            this.ccPayMethod.expire_year= values.expYear;
            this.ccPayMethod.card_verification_value= values.security;
            this.ccPayMethod.billing_address= {
                    city: values.city,
                    countryCode: values.country,
                    state: values.state,
                    street: values.address,
                    postalCode: values.zipCode
                };
            
           await  this.createCCPayMethod(this.ccPayMethod);
            if(!this.payMethodInfo.status)
            {
                this.$message.error(this.payMethodInfo.message);
                this.loading=false;
                this.loadingText='Loading ....';
                return ;

            }

            /** Card method creation values  End*/

            if (this.transactionAccount.sessionData.pgType == 'SWIREPAY' && this.currency !='INR' ) {
                await this.establishSession();
            }

            else{
                 /** Payment Capture object -start */
              
                await this.initiate_verify_Transaction();
            }
        },
        async establishSession(){
            this.loading=true;
            this.loadingText="Verifying the session..... Please wait.....";
              // setup session Block
              let setupSessionData = {
                            env: this.transactionAccount.isLive?1:0,
                            orgId: '',
                            locId: '',
                            partnerAccountId: '',
                            currencyCode: this.currency,
                            cardType: 'CARD',
                            paymentToken: this.payMethodInfo.result.paymethod_token
                        }
                await this.setupSession(setupSessionData);

            if(!this.setupSessionInfo.status)
            {
                this.$message.error(this.setupSessionInfo.message);
                this.loading=false;
                this.loadingText='Loading ....';
                return ;
            }

            if (this.setupSessionInfo.result.status == "VERIFIED") {
                await this.initiate_verify_Transaction();
            }
            else{
                // this.loading=false;
                this.loadingText= 'Loading.....!';
                // const h = this.$createElement;
                this.$alert(`<p>
                        Your payment method validation requires action. Please click on <b>“Authenticate”</b> button to verify your payment method and you will be redirected to a secure page.
                    </p><br>  <p style="color:red">
                        <b>NOTE:</b> After successful verification, kindly proceed by clicking on the <b>“Proceed”</b>
                        button to  complete the payment process.
                    </p> <br> <a href='${this.setupSessionInfo.result.nextActionUrl}' target='_blank' style="background-color: #e6a23c;border: none;padding: 10px 32px;text-align: center;text-decoration: none;display: inline-block;font-size: 16px;margin: 4px 2px;cursor: pointer;background-color: white;border: 2px solid #e6a23c;border-radius:25px;color:#e6a23c;" >Authenticate</button>
                    `, 'Attention!', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: 'Proceed',
                  showClose:false,
                  closeOnClickModal:false,
                  closeOnPressEscape:false,
                  closeOnHashChange:false,
        }).then(action => {
                console.log("Confirm ",action)
                this.establishSession();
                
                });
            }
            
        },

        async initiate_verify_Transaction()
        {
            this.checkoutInfo={
                    env: this.transactionAccount.isLive?1:0,
                    action: this.transactionAccount.sessionData.pgType=='SWIREPAY'? this.payMethodInfo.type=='CARD'?'CARD':'ACH_LEGACY':this.transactionAccount.sessionData.pgType=='FORTE'?'sale':'',  //CARD ,ACH_LEGACY , for forte: sale
                    authorization_amount: this.transactionAccount.sessionData.pgType=='SWIREPAY' ?this.amount *100:this.amount,
                    currencyCode:this.currency,
                    paymethod_token: this.payMethodInfo.result.paymethod_token,
                    paymentType: this.payMethodInfo.type=='CARD'?'':'echeck', //echeck (ach)
                    sec_code: this.transactionAccount.sessionData.pgType=='FORTE'?'WEB':''   //WEB for forte
                }
                /** payment capture object - end */
                await this.paymentInitiate(this.checkoutInfo);

                if (!this.paymentInfo.status) {
                    this.$message.error(this.paymentInfo.message);
                    this.loading=false;
                    this.loadingText='Loading ....';
                    return ;
                }
           
                let transactionId = this.paymentInfo.result.transaction_id;

                await this.verifyTransaction(transactionId);
                if (!this.transactionInfo.status) {
                    this.$message.error(this.transactionInfo.message);
                    this.loading=false;
                    this.loadingText='Loading ....';
                    return ;
                }
                if (this.transactionInfo.pgResponse === 'FORTE') {
                    this.addTransactionToVerify(this.transactionInfo.result, this.transactionInfo.pgResponse)
                }
                else if(this.payMethodInfo.type=='ACH')
                {
                    this.addTransactionToVerify(this.transactionInfo.result, this.transactionInfo.pgResponse)
                }
                else if (this.transactionInfo && this.transactionInfo.result && this.transactionInfo.result.response && this.transactionInfo.result.response.response_desc === 'SUCCEEDED') {
                    this.paymentStatusUpdate(this.transactionInfo.result,this.payMethodInfo.result.paymethod_token,this.customerInfo.result.customer_token)
                }

        },
        cardType(val) {
            console.log(val)
        },
    },
    computed:{
        getAchStatus(){
            return this.currency=='INR'?true:false;
        }
    },
    async mounted() {
        // console.log("entityInfo",this.transactionAccount)
    },

};
</script>

<style lang="scss" scoped>

</style>
